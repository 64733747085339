<template>
  <div class="user-form">
    <div class="wrapper position-absolute">
      <img :src="user_db.image ? user_db.image_base64 : require('@/assets/images/avatars/generic-avatar.png')" class="profile-image rounded-circle" alt="">
      <div class="overlay" @click="onPickFile">
        <p class="h2">
          <b-icon class="icon" icon="upload" variant="light"></b-icon>
        </p>
        <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked" />
      </div>
    </div>

    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="12">
          <validation-observer ref="newUserForm">
            <b-form @submit.prevent>
              <b-row>
                <b-col class="text-center mb-1">
                  <div v-if="$auth.db.children.length >= $auth.db.family.children && !edit">
                    <b-alert variant="warning" show class="pt-1 pb-1">
                      <strong>Wil je een extra familielid toevoegen?</strong>
                      <div class="mt-1">
                        Je hebt het limiet van {{ $auth.db.family.children }} {{ $auth.db.family.children == 1 ? 'familielid' : 'familieleden' }} bereikt.
                        <router-link to="/familiebeheer/administratie">Klik hier</router-link> om je limiet te verhogen. <br />
                        Partners kun je wel toevoegen.
                      </div>
                    </b-alert>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Voornaam" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="voornaam" rules="required">
                      <b-form-input placeholder="Voornaam" type="text" v-model="user.firstname" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Tussenvoegsel en achternaam" label-cols-md="4">
                    <b-row>
                      <b-col cols="4">
                        <validation-provider #default="{ errors }" name="tussenvoegsel" rules="">
                          <b-form-input placeholder="" type="text" v-model="user.infix" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="8">
                        <validation-provider #default="{ errors }" name="achternaam" rules="required">
                          <b-form-input type="text" placeholder="Achternaam" v-model="user.lastname" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Geslacht" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="geslacht" rules="required">
                      <b-form-select :options="['Man', 'Vrouw', 'Zeg ik liever niet']" v-model="user.gender" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Email" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="email" :rules="emailRules">
                      <b-form-input type="email" :placeholder="!isEdit ? 'voornaam.achternaam@email.com' : ''" v-model="user.email" :disabled="isEdit" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="12">
                  <b-form-group
                    label="Telefoonnummer"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="telefoonnummer"
                      rules="required|min:12"
                    >
                      <cleave
                        class="form-control"
                        v-model="user.phone"
                        :options="maskOptions.phone"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col> -->
                <b-col cols="12">
                  <b-form-group label="Geboortedatum" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="datum" :rules="birthdayRules">
                      <cleave v-model="user.birthdate" placeholder="01-10-1988" :options="maskOptions.date" :raw="false" :value="user.birthdate.split('-').reverse().join('-')" class="form-control" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col v-if="id == 'editUser'" cols="12">
                  <b-form-group label="Wat voor soort relatie betreft het?" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="Type relatie" rules="required">
                      <!-- in the vue select, we use the 'transition' prop. Without this set, the modal will close if you select an item that is outside of the modal (for reference: https://github.com/sagalbot/vue-select/issues/1244 ) -->
                      <v-select v-model="user.relation_type" label="label" :class="{ 'is-invalid': !!errors.length }" :disabled="isEdit" :options="relationshipOptions" @search:blur="blur_relation" placeholder="Type familielid" transition="" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="user.relation_type && user.relation_type.code == 'partner'">
                  <b-form-group label="Wiens partner is dit?" label-cols-md="4">
                    <div class="d-flex align-items-center">
                      <validation-provider #default="{ errors }" name="Partner selectie" rules="required" class="w-100">
                        <v-select v-model="user.userSelected" label="title" :disabled="isEdit" :options="users" style="width:100%;" placeholder="Selecteer gebruiker" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col v-if="user.relation_type && user.relation_type.code == 'child'" cols="12">
                  <b-form-group label-cols-md="4">
                    <template slot="label">
                      Wil je jouw familielid een eigen inlog geven?
                      <InfoButton id="child-login" :bgBlue="true" text="We sturen een email naar je familielid waarmee hij/zij een eigen account kan aanmaken." />
                    </template>
                    <validation-provider #default="{ errors }" name="Login familielied" rules="required">
                      <div class="d-flex ">
                        <b-form-checkbox :disabled="user_db.login || user_db.invited" :checked="isEdit && edit.sub" v-model="user.login"></b-form-checkbox>
                        <p>
                          Vink aan als je je familielid een inlog wilt geven.
                        </p>
                        <InfoButton v-if="user_db.login || user_db.invited" id="family-login-idf" text="Wanneer een familielid een login heeft, is deze niet meer uit te zetten. Mocht je het account willen verwijderen, neem contact op met info@familiebank.nl" :bgBlue="true" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>


              <b-row v-if="isEdit">
                <!-- <b-col cols="12" v-if="edit.partners.length>0">
                  <b-form-group
                    label-cols-md="4"
                  >
                    <template slot="label">
                      Is {{user.firstname}} gescheiden?
                    </template>
                    <div class="d-flex align-items-center">
                      <b-form-checkbox
                        v-model="user.divorced"
                      ></b-form-checkbox>

                      <validation-provider
                        #default="{ errors }"
                        name="datum"
                        rules="required"
                        v-if="user.divorced"
                      >
                        <cleave
                          v-model="user.date_divorced"
                          placeholder="01-10-2000"
                          :options="maskOptions.date2"
                          :raw="false"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-form-group>
                </b-col> -->

                <b-col cols="12">
                  <b-form-group label-cols-md="4">
                    <template slot="label">
                      Vink aan indien {{ user.firstname }} overleden is:
                    </template>
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="user.passed_away"></b-form-checkbox>

                      <validation-provider #default="{ errors }" name="overlijdensdatum" rules="required" v-if="user.passed_away">
                        <cleave v-model="user.death_date" placeholder="01-10-2000" :options="maskOptions.date2" :raw="false" class="form-control" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <div class="btn-group">
                    <b-button :disabled="($auth.db.children.length >= $auth.db.family.children && user.relation_type.code != 'partner') && !edit" variant="success" @click="addUser">
                      {{ isEdit ? 'Wijzig' : 'Opslaan' }}
                    </b-button>
                    <b-button variant="warning" @click="$emit('reloadUsers'); $bvModal.hide('add-relation'); $bvModal.hide('edit-relation-' + user.hashid)">
                      Annuleer
                    </b-button>
                    <b-button v-if="isEdit && !edit.blocked" variant="danger" @click="deleteUser">
                      Blokkeren
                    </b-button>
                  </div>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import datepickerLabel from '@/components/datepicker.js'
import { required, email, digits } from '@validations'
// import {  } from 'vee-validate/dist/rules';
import Cleave from 'vue-cleave-component'
import InfoButton from '@/components/InfoButton.vue'

// import loadImage from 'blueimp-load-image'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.nl'

require("datejs")

export default {
  name: "addAUser",
  props: {
    edit: {
      default: null
    },
    id: {
      type: String,
      default: "editUser",
    },
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    InfoButton
  },
  watch: {
    // it could happen that a user select the checkbox of having a child and then also selects partner, but then realises that the new user is not a kid. The form will still have a partner selected. This will be solved in the following if statement
    'user.child': function (newVal) {
      if (newVal == true) {
        this.user.partner = false
        this.user.partnerSelected = null
      }
      if (newVal == false) {
        this.user.login = false

      }
    },
    'user.death_date': function (newVal) {
      if (newVal !== null) {
        this.user.passed_away = true
      }
    },
    'user.relation_type.code': function (newVal) {
      if (newVal === "child") {
        this.birthdayRules = `required|ageLimitChild|dateFormat`
        this.emailRules = `email|required`
      }
      else {
        this.birthdayRules = 'required|dateFormat'
        this.emailRules = `email`
      }
    },
    'edit': function (newVal) {
      if (newVal === null) {
        this.isEdit = false
        this.resetForm()
      }
      else {
        this.isEdit = true
        this.user = this.edit
      }
    },
  },
  data() {
    let u = {
      image: null,
      profile: null,
      firstname: "",
      infix: "",
      lastname: "",
      gender: "",
      email: "",
      phone: "",
      birthdate: "",
      relation_type: {
        code: null,
        label: null
      },
      partners: [],
      child: false,
      partnerSelected: null,
      partner: false,
      partnerOptions: [],
      login: false,
      divorced: false,
      passed_away: false,
      death_date: null,
    };
    return {
      birthdayRules: "required|dateFormat",
      maskOptions: {
        date: {
          date: true,
          datePattern: ['d', 'm', 'Y'],
          delimiter: "-",
          dateMin: '1920-01-01',
          // dateMax: max_birth_date,
        },
        date2: {
          date: true,
          datePattern: ['d', 'm', 'Y'],
          delimiter: "-"
        },
        phone: {
          phone: true,
          phoneRegionCode: "NL",
          prefix: "+31",
        }
      },
      addAUser: false,
      datepickerLabel,
      isEdit: false,
      emailRules: "email",
      user: u,
      user_db: JSON.parse(JSON.stringify(u)),
      imageUrl: "",
      updated_image: false,
      users: [],
      // validation rules
      required,
      email,
      digits,
    }
  },
  mounted() {
    console.log("db: ", this.$auth.db)
    let _this = this;
    if (this.edit) {
      this.isEdit = true;
      this.user = this.edit;
      console.log("editing:", this.user);
      this.user_db = JSON.parse(JSON.stringify(this.user));
      console.log("USERDB", this.user_db);

      if (this.user.relation_type === "child") {
        this.user.relation_type = { label: 'Familielid', code: 'child' };
      }
      if (this.user.relation_type === "partner") {
        this.user.relation_type = { label: 'Een partner', code: 'partner' };
      }

      if (this.user.relation_type.code === "partner") {
        this.user.userSelected = { title: this.user.partners[0].self.firstname, value: this.user.partners[0].partner.hashid };
      }
    }
    this.$http.get("/users", { params: { own: 1 } }).then((response) => {
      _this.users = response.data.users.filter(e => e.partners.length == 0).map(e => { return { title: e.firstname, value: e.hashid }; });
    });
  },
  methods: {
    blur_relation() {
      console.log(this.$refs.newUserForm.refs);

    },
    addUser() {
      let _this = this;
      console.log(this.$refs.newUserForm.refs);
      this.$refs.newUserForm.validate().then((success) => {
        if (success) {
          this.$refs.newUserForm.refs["Type relatie"].validate().then((success) => {
            if (success) {
              this.user_db = JSON.parse(JSON.stringify(this.user));
              if (this.user_profile_image)
                this.user_db.profile_image = this.user_profile_image;
              this.user_db.relation_type = this.user_db.relation_type.code

              if (this.user_db.userSelected) {
                this.user_db.userSelected = this.user_db.userSelected.value
              }
              if (this.isEdit) this.user_db._method = "PATCH"

              this.user_db.birthdate = this.user_db.birthdate.split('-').reverse().join('-')
              if (this.user_db.death_date) {
                this.user_db.death_date = this.user_db.death_date.split('-').reverse().join('-')
              }

              let fd = new FormData();
              for (let property in this.user_db) {
                fd.append(property, this.user_db[property]);
              }

              _this.$http.post("/user", fd).then((response) => {
                if (response) {
                  _this.$emit(_this.isEdit ? 'editUser' : 'addUser', response.data.user)
                  if (_this.isEdit) {
                    _this.$swal("Persoon gewijzigd.", "Persoon is gewijzigd en opgeslagen.", "success");
                    _this.$bvModal.hide('edit-relation-' + _this.user.hashid)
                  } else {
                    if (_this.user.login) {
                      _this.$swal("Persoon toegevoegd.", "Je kunt nu contracten aangaan met de toegevoegde persoon. Deze persoon heeft ook een e-mail gekregen om een account aan te maken.", "success");
                    } else {
                      _this.$swal("Persoon toegevoegd.", "Je kunt nu contracten aangaan met de toegevoegde persoon.", "success");
                    }
                    _this.$bvModal.hide('add-relation')
                  }
                }
              });
            }
          })
        }
      });
    },
    deleteUser() {
      let _this = this;
      this.$http.post("/user/block", {
        hashid: this.user.hashid,
        _method: "PATCH"
      }).then(() => {
        _this.$emit('deleteUser', _this.user)
        _this.$bvModal.hide('edit-relation-' + _this.user.hashid)
      });
    },
    onPickFile() {
      this.$refs.fileInput.click()
    },
    gImg(img) { return URL.createObjectURL(img); },
    onFilePicked(event) {
      let _this = this;
      const files = event.target.files
      if (!files[0]) {
        return;
      }
      if (files[0].size > 4 * 1024 * 1000) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Foto is te groot, max 4MB',
            icon: 'InfoIcon',
            variant: 'danger',
          },
        });
      } else {
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          _this.imageUrl = fileReader.result;
          _this.user_profile_image = files[0];

          _this.user_db.image = true;
          _this.user_db.image_base64 = _this.imageUrl;
          _this.updated_image = true;
        });
        fileReader.readAsDataURL(files[0]);
      }
    }
  },
  computed: {
    relationshipOptions() {
      console.log("users: ", this.users)
      console.log("children length: ", this.$auth.db.children.length)
      console.log("number of children paid: ", this.$auth.db.family.children)
      if (this.$auth.db.children.filter(u => !u.signed).length == 0 && this.$auth.db.partner != null) {
        return [{ label: 'Familielid', code: 'child' }]
      }
      else if (this.$auth.db.children.filter(u => !u.signed).length >= this.$auth.db.family.children) {
        return [{ label: 'Een partner', code: 'partner' }]
      }
      else if (this.$auth.db.children.filter(u => !u.signed).length < this.$auth.db.family.children) {
        return [{ label: 'Familielid', code: 'child' }, { label: 'Een partner', code: 'partner' }]
      }
      else {
        return [{ label: 'Familielid', code: 'child' }, { label: 'Een partner', code: 'partner' }]
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.user-form {
  margin-top: 50px !important;

  .wrapper {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: -61.2px;
    height: 100px;
    width: 100px;

    .profile-image {
      height: 100%;
      width: 100%;
      position: relative;
      border-radius: 100%;
      border: 5px solid white;
      -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    }

    .overlay {
      position: absolute;
      border-radius: 100%;
      border: 5px solid white;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      background-color: rgba(102, 102, 102, 0.75);

      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .wrapper:hover .overlay {
    opacity: 1;
  }
}
</style>
