// import { $themeConfig } from '@themeConfig'
import axios from '@axios'

export default {
    namespaced: true,
    state: {
        codes: []
    },
    mutations: {
        //synced
        all(state, payload) {
            state.codes = payload;
        },
        add(state, payload) {
            state.codes.push(payload);
        },
        delete(state, payload) {
            state.codes.splice(payload.index, 1);
        }
    },
    actions: {
        //asynced 
        async all(state) {
          try {
            const response = await axios.get("/partnercodes", {
                headers: { 'Cache-Control': 'no-cache' }
            });
            const codes = response.data ? response.data.codes : [];
            state.commit('all', codes);
        } catch (error) {
            console.error("Error fetching partner codes:", error);
        }
        },
        async add(state, payload) {
            let form = new FormData();
            Object.keys(payload).forEach(key => {
                form.append(key, payload[key]);
            });
            console.log(form.values());
            axios.post("/partnercode", form).then(response => {
                state.commit("add", response.data.code);
            }).catch((error) => {
                console.log(error);
            });
        },
        async delete(state, payload) { //{id: x, index: x}
            axios.delete('/partnercode', {data: payload}).then(() => {
                state.commit("delete", {index: payload.index});
            }).catch(error => {
                console.log(error);
            })
        }
    },
    getters: {
        get: state => state.codes
    },
}
