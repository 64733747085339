import { extend } from "vee-validate";

extend('customPassword', {
  validate(value) {
    let pass = true;

    let containsLowerCase = new RegExp('[a-z]')
    let containsUpperCase = new RegExp('[A-Z]')
    let containsNumber = new RegExp('[0-9]')
    // eslint-disable-next-line
    let containsSpecialCharachter = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)

    if(value.length < 8) {
      pass = false
    }

    if(!containsLowerCase.test(value) || !containsUpperCase.test(value) || !containsNumber.test(value) || !containsSpecialCharachter.test(value)) {
      pass = false
    }

    return pass
  },
  message: 'Wachtwoord moet bestaan uit 8 tot 80 characters, één hoofdletter, één kleine letter, een cijfer en een symbool'
});

extend('ageLimitParent', {
  validate(value, ageParent) {

    let newAgeParent = ageParent[0].split("-").reverse().join("-")
    let newAgeChild = value.split("-").reverse().join("-")

    if(new Date(newAgeParent) >= new Date(newAgeChild)) {
      return false
    }
    else{
      return true
    }
  },
  message: 'Je kind kan niet ouder zijn dan jijzelf'
});

extend('ageLimitChild', {
  validate(value) {
    let maxBirthDate = (new Date()).add(-18).years();
    let birthDate = new Date(value.split('-').reverse().join('-'))
      
      if( birthDate > maxBirthDate) {
        return false
      } else {
        return true
      }
  },
  message: "Je familielid mag niet jonger zijn dan 18 jaar."
})

extend('dateFormat', {
  validate(value) {

    let literal = /^\d{1,2}-\d{1,2}-\d{4}$/gm;
    let regex = new RegExp(literal)
    let bCorrectDateFormat = regex.test(value);
    return bCorrectDateFormat
  },
  message: 'Datum is niet in het formaat DD-MM-JJJJ'
});