export default [
  {
    path: '/register/:invitation?',
    name: 'register',
    component: () => import('@/views/authentication/Register.vue'),
    meta: {
      layout: 'full',
    }
  },
  {
    path: '/register/step/final',
    name: 'register-step-final',
    component: () => import('@/views/authentication/steps/RegisterStepFinal.vue'),
    meta: {
      layout: 'full',
    }
  }
]