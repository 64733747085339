import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/other/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import contracts from './contracts'
import partnercodes from './partnercodes'
import loading from "./loading"
import user from "./user"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    app,
    appConfig,
    verticalMenu,
    loading,
    contracts,
    partnercodes,
  },
  strict: process.env.DEV,
})
