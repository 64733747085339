import Vue from 'vue'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)
require('@core/assets/fonts/feather/iconfont.css')

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

import AddRelationsModal from '@/views/family-management/modals/AddRelationsModal.vue'
Vue.component('AddRelationsModal', AddRelationsModal)

import VueMask from 'v-mask'
Vue.use(VueMask);


import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import InfoButton from '@/components/InfoButton.vue'
Vue.component("InfoButton", InfoButton);

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2, {
  confirmButtonColor: '#262a37',
  cancelButtonColor: '#262a37',
});

// Vee-validate
import { localize, ValidationProvider, extend } from "vee-validate";
import { required, numeric, min_value } from "vee-validate/dist/rules";
import nl from "vee-validate/dist/locale/nl.json";

localize({
  nl
});
localize("nl");
extend("required", required);
extend("numeric", numeric);
extend("min_value", min_value);

Vue.component("ValidationProvider", ValidationProvider);