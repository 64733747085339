import { authGuard } from "@/auth/guard";

export default [
  {
    path: '/admin',
    name: 'admin',
    beforeEnter: authGuard,
    component: () => import('@/views/admin/Admin.vue'),
  },
  {
    path: "/admin/:adminId",
    name: "admin-profile",
    beforeEnter: authGuard,
    component: () => import('@/views/admin/AdminProfile.vue'),
  }
]