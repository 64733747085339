import { authGuard } from "@/auth/guard";

export default [
  {
    path: '/familiebeheer/relaties',
    name: 'relations',
    beforeEnter: authGuard,
    component: () => import('@/views/family-management/Relations.vue'),
  },
  {
    path: '/familiebeheer/administratie',
    name: 'administration',
    beforeEnter: authGuard,
    component: () => import('@/views/family-management/Administration.vue'),
  },
]
