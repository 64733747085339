const DatepickerLabel = {
  labelPrevDecade: 'Vorig decennium',
  labelPrevYear: 'Vorig jaar',
  labelPrevMonth: 'Vorige maand',
  labelCurrentMonth: 'Huidige maand',
  labelNextMonth: 'Volgende maand',
  labelNextYear: 'Volgend jaar',
  labelNextDecade: 'Volgend decennium',
  labelToday: 'Vandaag',
  labelSelected: 'Geselecteerd',
  labelNoDateSelected: 'Selecteer datum',
  labelCalendar: 'Kalender',
  labelNav: 'Kalender navigatie',
  labelHelp: 'Met de pijltjestoetsen kunt u door de kalender navigeren'
};

export default DatepickerLabel;