import { authGuard } from "@/auth/guard";

export default [
  {
    path: '/profiel',
    name: 'profile',
    beforeEnter: authGuard,
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      
    }
  },
]
