// import { $themeConfig } from '@themeConfig'
// import axios from '@axios'

export default {
    namespaced: true,
    state: {
        loading_map: {},
        loading_counter: 0,
        loading: false
    },
    mutations: {
        //synced
        set(state, payload) {
            if(state.loading_map[payload] === undefined || !state.loading_map[payload]) {
                state.loading_counter += 1;
                state.loading_map[payload] = true;
                state.loading = true;
            }
        },
        done(state, payload) {
            if(state.loading_map[payload] !== undefined && state.loading_map[payload]) {
                state.loading_map[payload] = false;
                state.loading_counter -= 1;
                if(state.loading_counter == 0) {
                    state.loading = false;
                } else {
                    state.loading = true;
                }
            }
        }
    },
    actions: {
        //asynced
        async set(state, payload) {
            state.commit("set", payload);
        },
        async done(state, payload) {
            state.commit("done", payload);
        }
    },
    getters: {
        get: state => state.loading,
    },
}
