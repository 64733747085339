import { authGuard } from "@/auth/guard";

export default [
  {
    path: '/overzicht',
    name: 'overview',
    beforeEnter: authGuard,
    component: () => import('@/views/overview/Overview.vue')
  },
]
