// import { $themeConfig } from '@themeConfig'
// import axios from '@axios'

// import axios from "axios";

export default {
    namespaced: true,
    state: {
        user: null
    },
    mutations: {
        //synced
        set(state, payload) {
            state.user = payload;
        }
    },
    actions: {
        //asynced 
        // async set(state) {
        //     const data = await axios.get("/user");
        //     state.commit("set", data.data.user);
        // },
        // async logout(state) {
            
        // }
    },
    getters: {
        get: state => state.user
    },
}
