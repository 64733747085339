var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-form"},[_c('div',{staticClass:"wrapper position-absolute"},[_c('img',{staticClass:"profile-image rounded-circle",attrs:{"src":_vm.user_db.image ? _vm.user_db.image_base64 : require('@/assets/images/avatars/generic-avatar.png'),"alt":""}}),_c('div',{staticClass:"overlay",on:{"click":_vm.onPickFile}},[_c('p',{staticClass:"h2"},[_c('b-icon',{staticClass:"icon",attrs:{"icon":"upload","variant":"light"}})],1),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onFilePicked}})])]),_c('b-container',[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"newUserForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"text-center mb-1"},[(_vm.$auth.db.children.length >= _vm.$auth.db.family.children && !_vm.edit)?_c('div',[_c('b-alert',{staticClass:"pt-1 pb-1",attrs:{"variant":"warning","show":""}},[_c('strong',[_vm._v("Wil je een extra familielid toevoegen?")]),_c('div',{staticClass:"mt-1"},[_vm._v(" Je hebt het limiet van "+_vm._s(_vm.$auth.db.family.children)+" "+_vm._s(_vm.$auth.db.family.children == 1 ? 'familielid' : 'familieleden')+" bereikt. "),_c('router-link',{attrs:{"to":"/familiebeheer/administratie"}},[_vm._v("Klik hier")]),_vm._v(" om je limiet te verhogen. "),_c('br'),_vm._v(" Partners kun je wel toevoegen. ")],1)])],1):_vm._e()])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Voornaam","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"voornaam","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Voornaam","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tussenvoegsel en achternaam","label-cols-md":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"tussenvoegsel","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.user.infix),callback:function ($$v) {_vm.$set(_vm.user, "infix", $$v)},expression:"user.infix"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"achternaam","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Achternaam","state":errors.length > 0 ? false : null},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Geslacht","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"geslacht","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":['Man', 'Vrouw', 'Zeg ik liever niet']},model:{value:(_vm.user.gender),callback:function ($$v) {_vm.$set(_vm.user, "gender", $$v)},expression:"user.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"email","rules":_vm.emailRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","placeholder":!_vm.isEdit ? 'voornaam.achternaam@email.com' : '',"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Geboortedatum","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"datum","rules":_vm.birthdayRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"placeholder":"01-10-1988","options":_vm.maskOptions.date,"raw":false,"value":_vm.user.birthdate.split('-').reverse().join('-'),"state":errors.length > 0 ? false : null},model:{value:(_vm.user.birthdate),callback:function ($$v) {_vm.$set(_vm.user, "birthdate", $$v)},expression:"user.birthdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.id == 'editUser')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Wat voor soort relatie betreft het?","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Type relatie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'is-invalid': !!errors.length },attrs:{"label":"label","disabled":_vm.isEdit,"options":_vm.relationshipOptions,"placeholder":"Type familielid","transition":""},on:{"search:blur":_vm.blur_relation},model:{value:(_vm.user.relation_type),callback:function ($$v) {_vm.$set(_vm.user, "relation_type", $$v)},expression:"user.relation_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,10693171)})],1)],1):_vm._e(),(_vm.user.relation_type && _vm.user.relation_type.code == 'partner')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Wiens partner is dit?","label-cols-md":"4"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Partner selectie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"width":"100%"},attrs:{"label":"title","disabled":_vm.isEdit,"options":_vm.users,"placeholder":"Selecteer gebruiker"},model:{value:(_vm.user.userSelected),callback:function ($$v) {_vm.$set(_vm.user, "userSelected", $$v)},expression:"user.userSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1934004443)})],1)])],1):_vm._e(),(_vm.user.relation_type && _vm.user.relation_type.code == 'child')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4"}},[_c('template',{slot:"label"},[_vm._v(" Wil je jouw familielid een eigen inlog geven? "),_c('InfoButton',{attrs:{"id":"child-login","bgBlue":true,"text":"We sturen een email naar je familielid waarmee hij/zij een eigen account kan aanmaken."}})],1),_c('validation-provider',{attrs:{"name":"Login familielied","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex "},[_c('b-form-checkbox',{attrs:{"disabled":_vm.user_db.login || _vm.user_db.invited,"checked":_vm.isEdit && _vm.edit.sub},model:{value:(_vm.user.login),callback:function ($$v) {_vm.$set(_vm.user, "login", $$v)},expression:"user.login"}}),_c('p',[_vm._v(" Vink aan als je je familielid een inlog wilt geven. ")]),(_vm.user_db.login || _vm.user_db.invited)?_c('InfoButton',{attrs:{"id":"family-login-idf","text":"Wanneer een familielid een login heeft, is deze niet meer uit te zetten. Mocht je het account willen verwijderen, neem contact op met info@familiebank.nl","bgBlue":true}}):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4221462664)})],2)],1):_vm._e()],1),(_vm.isEdit)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4"}},[_c('template',{slot:"label"},[_vm._v(" Vink aan indien "+_vm._s(_vm.user.firstname)+" overleden is: ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{model:{value:(_vm.user.passed_away),callback:function ($$v) {_vm.$set(_vm.user, "passed_away", $$v)},expression:"user.passed_away"}}),(_vm.user.passed_away)?_c('validation-provider',{attrs:{"name":"overlijdensdatum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"placeholder":"01-10-2000","options":_vm.maskOptions.date2,"raw":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.user.death_date),callback:function ($$v) {_vm.$set(_vm.user, "death_date", $$v)},expression:"user.death_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3687132602)}):_vm._e()],1)],2)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('div',{staticClass:"btn-group"},[_c('b-button',{attrs:{"disabled":(_vm.$auth.db.children.length >= _vm.$auth.db.family.children && _vm.user.relation_type.code != 'partner') && !_vm.edit,"variant":"success"},on:{"click":_vm.addUser}},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Wijzig' : 'Opslaan')+" ")]),_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){_vm.$emit('reloadUsers'); _vm.$bvModal.hide('add-relation'); _vm.$bvModal.hide('edit-relation-' + _vm.user.hashid)}}},[_vm._v(" Annuleer ")]),(_vm.isEdit && !_vm.edit.blocked)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.deleteUser}},[_vm._v(" Blokkeren ")]):_vm._e()],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }