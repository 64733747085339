<template>
  <span>
    <span v-if="bgBlue">
      <i :id="id" class="ml-1" v-b-tooltip.html :title="text">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#80BBDB" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </svg>
      </i>
      <b-tooltip :target="id"></b-tooltip>
    </span>
    <span v-else>
      <i :id="id" class="ml-1" v-b-tooltip.html :title="text">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg>
      </i>
      <b-tooltip :target="id"></b-tooltip>
    </span>

  </span>
</template>

<script>
// import store from '@/store'
import { BTooltip } from 'bootstrap-vue'



export default {
  name: "InfoButton",
  props: {
    bgBlue: {
      type: Boolean
    },
    text: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    BTooltip,
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.info {
  height: 15px;
  width: 15px;
  background-color: rgb(128, 187, 219);
  color: white;
  border-radius: 50%;
}
</style>
