// import { $themeConfig } from '@themeConfig'
import axios from '@axios'

export default {
    namespaced: true,
    state: {
        contracts: [],
        total_gifts: 0.0,
        total_loans: 0.0
    },
    mutations: {
        //synced
        all(state, payload) {
            state.contracts = [];
            state.total_gifts = 0.0;
            state.total_loans = 0.0;
            state.contracts = payload.sort((a , b) => {
                if(a.contract.signed_date && b.contract.signed_date && (new Date(a.contract.signed_date.replace(/-/g, '/').replace('T', ' ')) > new Date(b.contract.signed_date.replace(/-/g, '/').replace('T', ' ')))) {
                    return -1;
                } else {
                    return 1;
                }
            });
            state.contracts.forEach((item) => {
                if(item.type.indexOf('GiftContract') !== -1) {
                    state.total_gifts += item.total;
                }
                if(item.type.indexOf('LoanContract') !== -1) {
                    state.total_loans += item.total;
                }
            })
        },
        add(state, payload) {
            state.contracts.push(payload);
            if(payload.type.indexOf('GiftContract') !== -1) {
                state.total_gifts += payload.total;
            }
            if(payload.type.indexOf('LoanContract') !== -1) {
                state.total_loans += payload.total;
            }
        },
        edit(state, payload) {
            let index = state.contracts.findIndex((item) => { return item.hashid == payload.hashid; });
            if(index >= 0 && index < state.contracts.length) {
                state.contracts.splice(index, 1, payload);
            }
        },
        remove(state, payload) {
            let index = state.contracts.findIndex((item) => { return item.hashid == payload; });
            if(index >= 0 && index < state.contracts.length) {
                let item = state.contracts[index];
                if(item.type.indexOf('GiftContract') !== -1) {
                    state.total_gifts -= item.total;
                }
                if(item.type.indexOf('LoanContract') !== -1) {
                    state.total_loans -= item.total;
                }

                state.contracts.splice(index, 1);
            }
        }
    },
    actions: {
        //asynced 
        async all(state) {
            state.dispatch("loading/set", "contracts", {root:true});
            try {
                const contracts = await axios.get("/contracts");
                state.commit('all', contracts.data.contracts);
            } catch ( error ) {
                state.dispatch("loading/done", "contracts", {root:true});
            }
            state.dispatch("loading/done", "contracts", {root:true});
        },
        async add(state, payload) {
            if(payload.hashid === null || payload.hashid === undefined) {
                let promise = await axios.post("/contract", payload).then(response => {
                    state.commit('add', response.data.contract);
                });
                return promise;
            } else {
                let promise = axios.post("/contract", payload).then(response => {
                    state.commit('edit', response.data.contract);
                });
                return promise;
            }
        },
        async remove(state, payload) { //{id: x, index: x}
            return axios.delete("/contract", {
                params: { 
                    hashid: payload
                },
            }).then((response) => {
                console.log(response);
                console.log(payload);
                state.commit('remove', payload);
            });
        },
        async download(state, payload) {
            let promise = axios.post("/contract/generated", {
                hashid: payload
            }, {
                responseType: 'arraybuffer'
            }).then( response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Familiebank_Overeenkomst.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
            });
            return promise;
        },
        async preview(state, payload) {
            // let formData = new FormData();
            
            // let values = Object.values(payload);
            // let keys = Object.keys(payload);

            // keys.forEach((key, index)=> {
            //     if(!["data","created_at","deleted_at","updated_at","contract","pivot","senders","receivers","hashid"].includes(key) ) {
            //         console.log(key,values[index]);
            //         formData.append(key,values[index]);
            //     }
            // });
            // formData.append("data", item.data);
            // formData.append("senders", JSON.stringify(payload.senders));
            // formData.append("receivers", JSON.stringify(payload.receivers));
            // formData.append("interest", item.contract.interests[0].interest);
            // formData.append("length", item.contract.length);
            // formData.append("payment_period", item.contract.payment_period);

            return axios.post("/contract/preview", payload, {
                responseType: 'arraybuffer'
            }).then(function (response) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'download.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        }
    },
    getters: {
        get: state => state.contracts,
        get_total_gifts: state => state.total_gifts,
        get_total_loans: state => state.total_loans
    },
}
