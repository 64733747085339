import Vue from 'vue'

// axios
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

// import { getInstance } from "@auth";

import router from '@router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // console.log("Caught error haha!", error);
  // console.log(error.response);
  // console.log(error.response.data);
  // console.log(error.request);
  // console.log(error.message);
  console.log("test");
  
  switch(error.response.status) {
    case 401: {
      if(error.response.data.status === "mfa") {
        router.push({name: "mfa"}).catch(()=>{});
      }
    }break;

    case 503: {
        router.push({name: "maintenance"}).catch(()=>{});
    }break;

    case 500: {
      // const authService = getInstance();
      // authService.logout()
    }break

    case 422: {
      // const authService = getInstance();
      // authService.logout()
    }break
  }

  if(error.response && error.response.data.errors) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: 'Er is een fout opgetreden',
        text: Object.values(error.response.data.errors).join(" | "),
        icon: 'InfoIcon',
        variant: 'danger',
      },
    });
    return;
  }
  if(error.response && error.response.data.error) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: 'Er is een fout opgetreden',
        text: error.response.data.error,
        icon: 'InfoIcon',
        variant: 'danger',
      },
    });
    return;
  }
  if (error.response && error.response.data.message) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: 'Er is een fout opgetreden',
        text: error.response.data.message,
        icon: 'InfoIcon',
        variant: 'danger',
      },
    });
    return;
  } 
  Vue.$toast({
    component: ToastificationContent,
    props: {
      title: 'Er is een fout opgetreden',
      text: error.response.statusText,
      icon: 'InfoIcon',
      variant: 'danger',
    },
  });
  return Promise.reject(error);
});

if(!Vue.prototype.$http) {
  Vue.prototype.$http = axiosIns
}

export default axiosIns
