import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import overview from './routes/overview'
import annualstatement from './routes/annual-statement'
import familymanagement from './routes/family-management'
import account from './routes/account'
import loan from './routes/loan'
import admin from './routes/admin'
import profile from './routes/profile'

import { authGuard } from "@/auth/guard";

Vue.use(VueRouter)

console.log(process.env.VUE_APP_BASE_URL);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/melding',
      name: 'notification',
      component: () => import('@/views/notification/General.vue'),
      meta: {
        layout: "full"
      }
    },
    {
      path: '/mfa',
      name: 'mfa',
      component: () => import('@/views/authentication/mfa.vue'),
      meta: {
        layout: "full"
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/profile/Logout.vue'),
      meta: {
        layout: "full"
      },
    },
    {
      path: "/error",
      component: () => import("@/views/error/error"),
      meta: {
        layout: "full"
      }
    },
    ...account,
    ...overview,
    ...annualstatement,
    ...familymanagement,
    ...loan,
    ...admin,
    ...profile,
    { 
      path: '/', 
      beforeEnter: authGuard, 
      redirect: { path: '/overzicht' }
    },
    {
      path: '*',
      redirect: 'error-404',
      meta: {
        layout: "full"
      }
    },
  ],
})

router.beforeEach((to, from, next) => {
  if(to.query.error && to.path !== "/error") {
    next({
        path: '/error',
        query: to.query
    });
  }

  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
